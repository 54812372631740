<template>
    <div class="content-item tx-news module-news-list -max-width-limited">
        <div class="list__container" ref="list">
            <template v-if="loading">
                <div v-for="n in itemsPerPage" :key="`loading-${n}`" class="list_card list_card--loading col col__b1-6 col__b2-4">
                    <div class="card__image"></div>
                    <div class="card__content">
                        <div class="content__date"></div>
                        <div class="content__title"></div>
                        <div class="content__text">
                            <div class="line line-one"></div>
                            <div class="line line-two"></div>
                            <div class="line line-three"></div>
                        </div>
                        <div class="content__link"></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-for="singleNews in news" :key="singleNews.id" class="list_card col col__b1-6 col__b2-4">
                    <a :href="singleNews.link">
                        <div class="card__image">
                            <img :src="singleNews.image.split('|')[0]" :alt="singleNews.image.split('|')[1]" :title="singleNews.image.split('|')[2]"/>
                        </div>
                        <div class="card__content">
                            <div class="content__date">
                                <p>{{ formatDate(singleNews.date) }}</p>
                            </div>
                            <div class="content__title">
                                <p>{{ singleNews.title }}</p>
                            </div>
                            <div class="content__text">
                                <p>{{ singleNews.text }}</p>
                            </div>
                            <div class="content__link">
                                <p class="icon-menu-link-before">{{ linkText }}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </template>
        </div>
        <div v-if="numberOfTotalItems > itemsPerPage" class="main_container_pagination_button">
            <div class="button_container">
                <div v-show="currentPage !== 1" class="pagination_button prev icon-arrow-left-before" @click="prevPage"></div>
                <div class="less_page" v-if="numberOfPages <= 5">
                    <div class="pagination_button index" :class="{ 'active' : page === currentPage }" v-for="page in numberOfPages" :key="page.id" @click="specificPage(page)">
                        {{ page }}
                    </div>
                </div>
                <div v-else class="more_page">
                    <div class="pagination_button special_index" :class="{ 'active' : page === currentPage }" v-for="page in pageRange()" :key="page.id" @click="specificPage(page)">
                        {{ page }}
                    </div>
                </div>
                <div v-show="currentPage !== numberOfPages" class="pagination_button next icon-arrow-right-before" @click="nextPage"></div>
            </div>
            <div class="info_element">
                <p>Es werden die Beiträge {{ firstItemPerPage }} bis {{ lastItemPerPage }} von insgesamt {{ numberOfTotalItems }} angezeigt.</p>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

export default {
    name: 'App',
    components: {},
    data ()
    {
        return {
            news: [],
            newsApiError: '',
            currentPage: 1,
            numberOfPages: '',
            firstItemPerPage: 1,
            lastItemPerPage: null,
            itemsPerPage: 12,
            numberOfTotalItems: '',
            linkText: 'Weiterlesen',
            loading: false
        }
    },
    methods: {
        formatDate (date)
        {
            let timestamp = { second: date }
            let currentDate = new Date(timestamp.second * 1000)
            let curr_day = currentDate.getDate()
            let curr_month = currentDate.getMonth() + 1 // Add +1 for take the right month because the order is from 0 to 11
            let curr_year = currentDate.getFullYear()
            return ('0' + curr_day).slice(-2) + '.' + ('0' + curr_month).slice(-2) + '.' + curr_year
        },
        getNews (currentPage)
        {
            this.loading = true
            Axios.get(window.app.news_list.api_base + 'news-news/' + '?currentPage=' + currentPage + '&itemsPerPage=' + this.itemsPerPage)
                .then((response) =>
                {
                    this.news = response.data.news
                    this.numberOfPages = response.data.numberOfPages
                    this.numberOfTotalItems = response.data.numberOfTotalItems
                    this.loading = false
                })
                .catch((e) =>
                {
                    this.newsApiError += e.message
                })
        },
        changeNumOfItem ()
        {
            this.firstItemPerPage = (this.currentPage * this.itemsPerPage) - this.itemsPerPage + 1
            this.lastItemPerPage = this.currentPage * this.itemsPerPage
            if (this.lastItemPerPage > this.numberOfTotalItems)
            {
                this.lastItemPerPage = this.numberOfTotalItems
            }
            gsap.to(window, { duration: 0.5, scrollTo: this.$refs.list })
        },
        prevPage ()
        {
            if (this.currentPage !== 1)
            {
                this.currentPage--
                this.getNews(this.currentPage)
                this.changeNumOfItem()
            }
        },
        nextPage ()
        {
            if (this.currentPage !== this.numberOfPages)
            {
                this.currentPage++
                this.getNews(this.currentPage)
                this.changeNumOfItem()
            }
        },
        specificPage (index)
        {
            this.currentPage = index
            this.getNews(this.currentPage)
            this.changeNumOfItem()
        },
        pageRange ()
        {
            let start = this.currentPage - 2
            let end = this.currentPage + 2

            if (end > this.numberOfPages)
            {
                start -= (end - this.numberOfPages)
                end = this.numberOfPages
            }
            if (start <= 0)
            {
                end += ((start - 1) * (-1))
                start = 1
            }
            end = end > this.numberOfPages ? this.numberOfPages : end

            let middle = start + 2
            let middleMinusOne = start + 1
            let middleOne = end - 1

            return {
                start: start,
                middleMinusOne: middleMinusOne,
                middle: middle,
                middleOne: middleOne,
                end: end
            }
        }
    },
    mounted ()
    {
        gsap.registerPlugin(ScrollToPlugin)

        this.getNews(this.currentPage)
        this.firstItemPerPage = 1
        this.lastItemPerPage = this.itemsPerPage
    }
}
</script>
